@import "styles/color.scss";
from #root {
  height: 100vh;
  background-color: white;
  width: 100%;
}
.main-layout-user {
  .header {
    height: fit-content;
    position: sticky;
    top: 0;
    max-height: calc(100vh - 55px);
  }
  .breadcrumb {
    .ant-breadcrumb {
      padding: 10px;
      font-size: 15px;
      background-color: #ffffff;
      li:last-child {
        background-color: #f0f0f0;
        border-radius: 5px;
        transition: padding 0.45s;
        span {
          padding: 2px 4px;
          transition: padding 0.45s;
        }
      }
      li {
        align-items: center;
      }
    }
  }

  .ant-layout-content {
    overflow-y: auto;
    // max-height: 100vh;
    max-height: calc(100vh - 55px);
  }
  .content-page-user {
    // min-height: calc(100vh - 80px - 20px);
    // height: calc(100vh - 80px - 20px);
    .title-test-online {
      background-color: $backgroundTitle;
      color: $colorTextWhite;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      font-weight: 600;
      margin: 0;
      position: sticky;
      top: 0;
      z-index: 9999;
      box-shadow: $colorTextSub;
    }
  }
}

.main-layout-user,
.ant-layout {
  height: 100vh;
}
@media only screen and (max-width: 576px) {
  .ant-layout-content {
    overflow-y: auto;
    max-height: calc(100vh - 100px) !important;
  }
}
