@import "styles/variables";
::-webkit-scrollbar {
  width: 6px !important;
  height: 10px !important;
  border-radius: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 4px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

/* Add this CSS to your stylesheet */
.table-container::-webkit-scrollbar {
  width: 12px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  padding: 0 !important;
  overflow: hidden;
  margin: 0 !important;
  box-sizing: border-box;
  .ant-row,
  div,
  a,
  span,
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: Arial, sans-serif;
  }
}
.ant-form-item {
  margin-bottom: 0;
}

// .ant-layout {
//   height: 100vh;
// }
.content-page {
  // padding: 15px;
}
.container {
  background: #ffffff;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 12px;
  @include box-shadow-1;
  // border-color: red;
  // border-width: 0.5px;
}
.ant-table-wrapper {
  .ant-table-cell {
    padding: 4px !important;
  }
  th {
    text-align: center !important;
  }
}
.ant-select-disabled {
  .ant-select-selector {
    color: black !important;
  }
}
.ant-input-disabled,
.ant-input-number-disabled {
  color: black !important;
}
button.btn-default {
}
.table-input-add {
  .editable-cell {
    position: relative;
  }

  .ant-table-cell .ant-form-item-control-input-content input.ant-input {
    border: 1px solid #d9d9d9 !important;
    overflow: hidden;
  }
  .ant-input-number {
    width: 100%;
  }
  .ant-select {
    height: 100%;
  }
  .editable-cell-value-wrap {
    cursor: pointer;
    height: 30px;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border-radius: 2px;
  }
  .editable-row-2 {
    .ant-form-item-control-input-content {
      input {
        height: 30px !important;
      }
    }
  }

  .btn-add-sub {
    border: none;
  }
  .ant-divider {
    margin: 5px 0 !important;
  }
}

.btn-animation {
  font-weight: 100;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  will-change: transform;
  z-index: 10;

  &::after {
    background-color: #1d5db1;
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-101%, 0);
    transform-origin: top left;
    transition: transform 0.2s ease-out;
    will-change: transform;
    z-index: -1;
  }

  &:hover {
    span {
      color: white;
    }
    // will-change: transform;

    &::after {
      transform: translate(0, 0);
    }
  }
}
label::after {
  display: none !important;
}

@media only screen and (max-width: 576px) {
  .custom-tag {
    width: 100%;
    text-align: center;
    flex-grow: 1;
    white-space: normal;
  }
}
