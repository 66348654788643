.ant-row {
  .input-fields {
    .ant-form-item {
      // height: 100%;
      margin-bottom: 0;
    }
    .ant-form-item-row {
      .ant-form-item-label {
        text-align: left;
      }
    }
  }
  .input-fields.hidden {
    display: none;
  }
}
