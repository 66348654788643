#root {
  height: 100vh;
  background-color: white;
  width: 100%;
}
.main-layout {
  .header {
    height: fit-content;
    position: sticky;
    top: 0;
  }
  .breadcrumb {
    margin-bottom: 12px;
    .ant-breadcrumb {
      padding: 0 !important;
      font-size: 15px;
      // background-color: #ffffff;
      li:last-child {
        background-color: #f0f0f0;
        border-radius: 5px;
        transition: padding 0.45s;
        span {
          padding: 2px 4px;
          transition: padding 0.45s;
        }
      }
      li {
        align-items: center;
      }
    }
  }
  .ant-layout-content {
    margin: 15px;
    overflow-y: scroll;
    position: relative;
  }
}
