@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");

.header-page,
.header-page-user {
  background-color: white;
  // padding-left: 24px;
  height: auto;
  min-height: 50px;
  padding: 0 12px;
  display: flex;
  width: 100%;
  & > .ant-col {
    max-height: 100%;
  }

  position: sticky;
  top: 0;
  z-index: 9999;
  box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #d3d7de;
  .header-logo {
    display: flex;
    gap: 5;
    padding: 10px 0;
  }
  .collapsed-btn {
    display: flex;
    align-items: center;
  }
  // background-color: orange;
  .img-logo {
    display: flex;
    align-items: center;
    img {
      width: 120px;
      height: 40px;
    }
  }
  .img-logo:hover {
    cursor: pointer;
    text-shadow: 0px 0px 20px;
  }
  .img-logo-mobile {
    display: none;
  }
  .slogan-text {
    display: flex;
    font-size: 25px;
    align-items: center;
    font-family: "roboto-mono", sans-serif;
    letter-spacing: 3px;
  }
  .dropdown-mobile {
    display: none;
  }
  .button-login {
    display: flex;
    align-items: center;
    .avatar-user:hover {
      cursor: pointer;
    }
    .avatar-user {
      height: 35px;
      width: 35px;
      background-color: #e55c37;
      .ant-avatar-string {
        font-size: 25px;
        font-weight: 500;
      }
    }
    margin-right: 15px;
  }
  .username {
    display: flex;
    align-items: center;
    .username-text {
      font-size: 16px;
      color: #e55c37;
      padding-right: 5px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .header-page-user {
    .img-logo {
      img {
        width: 150px;
        height: 50px;
      }
      .button-login {
        .avatar-user {
          height: 40px;
          width: 40px;
          background-color: #e55c37;
          .ant-avatar-string {
            font-size: 25px;
            font-weight: 500;
          }
        }
      }
    }
    .slogan-text {
      display: none;
    }
    .dropdown-mobile {
      display: block;
    }
    .btn-laptop {
      display: none;
    }
    .username {
      .username-text {
        display: none;
      }
    }
    // .ant-row {
    //   justify-content: end;
    // }
  }
}

@media only screen and (max-width: 762px) {
  .header-page-user {
    .slogan-text {
      display: none;
    }
    .dropdown-mobile {
      display: block;
    }
    .btn-laptop {
      display: none;
    }
  }
}
