.modal-custom {
  .ant-modal-content {
    padding: 0px;
    border-radius: 0;
    button.ant-modal-close {
      height: 26px !important;
      color: white;
      align-items: center;
    }
    .ant-modal-header {
      background-color: #002f6d;
      min-height: 60px;
      max-height: fit-content;
      padding: 12px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      min-height: 60px;
      border-radius: 0;
      .ant-modal-title {
        font-size: 20px;
        color: white;
      }
    }
    .ant-modal-body {
      margin: 12px;
      // border: 1px solid #eef0f3;
      border-radius: 8px;
      .content-modal {
        padding: 10px 40px;
        // overflow-y: auto;
      }
    }
    .modal-footer {
      border-top: 1px solid black;
      padding-top: 8px;
      display: flex;
      justify-content: flex-end;
      button {
        height: 32px !important;
      }
    }
    .ant-modal-footer {
      padding: 10px;
    }

    .ant-modal-footer {
      .ant-btn-default {
        border-color: #ff4d4f;
        color: #ff4d4f;
      }
      .ant-btn-default:hover {
        border-color: #ff8c8e;
        color: #ff8c8e;
      }
    }
  }
  .modal-content .ant-tag {
    flex-grow: 1;
    margin-right: 10px; /* Adjust the margin as needed */
    white-space: normal; /* Allow the tag content to wrap if necessary */
  }
}

@media only screen and (max-width: 576px) {
  .modal-custom {
    button.ant-modal-close {
      display: none;
    }
    .ant-modal {
      width: 80% !important;
    }
    .ant-modal-content {
      .ant-modal-footer {
        display: grid;
        row-gap: 5px;
        button {
          margin-inline-start: 0 !important;
          width: 100%;
        }
      }
    }
  }
}
