@import "../../styles/color.scss";
aside.sider {
  overflow-x: hidden;
  font-weight: bold;
  border-right: 1px solid #d3d7de;
  // box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);
  // .logo-container {
  //   height: 48px;
  //   background-color: $colorSub;
  //   color: $colorTextWhite !important;
  //   .ant-row {
  //     height: 100%;
  //   }
  //   img {
  //     width: 40px;
  //   }
  //   .sider-logo-title {
  //     img {
  //       height: 32px;
  //       width: 120px;
  //     }
  //     font-size: 18px !important;
  //   }
  // }

  ul.ant-menu-root {
    overflow-y: auto;
    height: calc(100vh - 70px);
    border-inline-end: none !important;
  }

  ul.ant-menu-inline-collapsed {
    // display: flex;

    svg {
      vertical-align: -0.6em !important;
      transform: translateX(-0.2em);
    }
  }
  li.ant-menu-submenu {
    .ant-menu-submenu-title {
      svg {
        height: 100%;
      }
    }
  }
  li.ant-menu-item {
    height: 48px !important;
    a.link-menu {
      color: $colorTextSub !important;
    }
    &.ant-menu-item-selected {
      a.link-menu {
        color: $colorPrimary !important;
      }
      svg {
        path {
          fill: $colorPrimary;
        }
      }
    }
    .ant-menu-item-icon {
      svg {
        width: 30px;
      }
    }
  }

  li.ant-menu-submenu {
    & > div > span.ant-menu-title-content {
      color: $colorTextSub !important;
    }
  }
  .ant-menu-submenu-title {
  }
  svg {
    width: 20px;
  }
  li.ant-menu-submenu-open,
  li.ant-menu-submenu-selected {
    & > div > span.ant-menu-title-content {
      color: $colorPrimary !important;
    }
    a.link-menu {
      color: $colorPrimary !important;
    }
    svg {
      path {
        fill: $colorPrimary;
      }
    }
    i {
      color: $colorPrimary;
    }

    ul.ant-menu-sub {
      li {
        padding-left: 40px !important;
        a.link-submenu {
          color: $colorTextSub !important;
        }
        &.ant-menu-item-selected {
          a.link-submenu {
            color: $colorPrimary !important;
          }
        }
      }
    }
  }

  .sub-menu {
    .sider-icon {
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }
}
