@import "styles/_color.scss";
.breadcrumb {
  nav {
    padding: 0px 12px !important;
  }

  a {
    color: black !important;
    &:hover {
      background-color: transparent !important;
      text-decoration: underline !important;
    }
  }
  li {
    background-color: transparent !important;
  }
  li:last-child {
    a {
      color: $colorPrimary2 !important;
    }
  }
}
